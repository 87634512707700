<template>
    <div 
        style="overflow:scroll;max-height:300px;min-height:80px;padding-top:20px"
        v-infinite-scroll="handleInfiniteOnLoad"
        :infinite-scroll-disabled="busy"
        :infinite-scroll-distance="10">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-timeline>
            <a-timeline-item v-for='(item,index) in list' :key="index">
                <div>
                    <img style="width:30px;height:30px;border-radius:50%" :src="item.created_by.employee_avatar">
                    <span style="margin-left:10px;">{{item.created_by.employee_name}}</span>
                    <span style="margin-left:10px">{{item.content}}</span>
                    <span style="margin-left:10px">{{item.created_at}}</span>
                </div>
                <div class="taskFollow-card-img">
                    <img :preview="index" v-for="(it,ind) in item.content_images" :key="ind" :src="it">
                </div>
            </a-timeline-item>
        </a-timeline>
        <LEmpty v-if="list.length == 0"/>
    </div>
</template>

<script>
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'editLog',
        directives: { infiniteScroll },
        data() {
            return {
                list: [],
                busy: false,
                loading:false,
                pageParams: {
                    'page': 0,
                    'per-page':10,
                },
            }
        },
        props: {
            leadsId: Number,
            refreshNum: Number,
        },
        created () {
            this.getLog(this.pageParams)
        },
        methods: {
            async getLog(obj) {
                this.loading = true
                obj.task_id = this.leadsId
                let res = await this.$store.dispatch('oaTaskLogAction', obj)
                if(this.list.length == 0){
                    this.list = res.items
                }else{
                    if(res.items.length == 0){
                        this.$message.warning('没有更多数据了!')
                        this.busy = true
                    }else{
                        this.list = this.list.concat(res.items)
                    }
                }
                this.loading = false
            },
            handleInfiniteOnLoad() {
                if(this.pageParams.page != 0){
                    if(!this.busy){
                        this.getLog(this.pageParams)
                    }
                }
                this.pageParams.page++
            },
        },
        watch: {
            leadsId(newValue, oldValue) {
                this.pageParams.page = 1
                this.list = []
                this.getLog(this.pageParams)
            },
            refreshNum(){
                this.pageParams.page = 1
                this.list = []
                this.getLog(this.pageParams)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .taskFollow-card-img{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        img{
            height: 60px;
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }
</style>