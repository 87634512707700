<template>
  <a-modal title="新建任务" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="700px"
    @ok="handleOk" @cancel="handleCancel" class="journal-modal">
    <a-form :form="form" layout="vertical">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-textarea v-if="item.type === 'textarea'" :rows="2" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-range-picker format="YYYY-MM-DD HH:mm" v-if="item.type === 'time'" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
      </a-form-item>
      <a-form-item label="审核人">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
              <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.employee_name}}</a-tag>
              <a-icon style='fontSize:18px' type="plus-circle" />
            </div>
          </a-popover>
        </div>
      </a-form-item>
      <a-form-item label="关联业务">
        <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
          <a-tag style="margin:5px" v-for="item in selectedRecord" :key="item.task_id">{{item.customer_name}}</a-tag>
          <a-icon style='fontSize:18px' @click="isCustomer = true" type="plus-circle" />
        </div>
      </a-form-item>
    </a-form>
    <customer v-if="isCustomer" />
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'content',
        label: '任务名称',
        type: 'input',
        rules: [{ required: true, message: '请输入任务名称!' }]
    },
    {
        name: 'timer',
        label: '任务时间',
        type: 'time',
        rules: [{ required: true, message: '请选择任务时间!' }]
    },
    {
        name: 'remark',
        label: '任务描述',
        type: 'textarea',
        rules: [],
    },
]
import checkWarp from '../approve/CheckWarp'
import customer from '@/views/market/huidongApply/coachDaily/customer'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
      return {
          parent: this
      }
  },
  props: {
    item: Object
  },
  components: {
    checkWarp,
    customer
  },
  data() {
    return {
      formData,
      confirmLoading: false,
      visible: false,
      loading: false,
      isCheckWarp: false,
      isCustomer: false,
      form: this.$form.createForm(this),
      active:1,
      check_name_list:[],
      selectedRecord:[],
      selectedRowKeys:[],
    }
  },

  async created() {
    this.$ls.remove('check_name_list')
    this.$ls.remove('send_employee_ids')
    this.$ls.remove('send_employee_list')
    this.$ls.remove('localSelectedRecord')
    this.$ls.remove('localSelectedRowKeys')
    this.visible = true
  },

  methods: {
    async handleOk() {

    },
    hideCheckWarp(val){
      if(val){
        this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
      }
      this.isCheckWarp = false
    },
    hideCustomer(val) {
      if(val){
        this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord'))
        this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys'))
      }
      this.isCustomer = false
    },
    handleCancel(){
      this.parent.hideEditModal()
    }
  },
}
</script>
