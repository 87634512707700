<template>
    <div class="taskTable" @click="hideFollowModal" style="height:100%">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>工作台</a-breadcrumb-item>
            <a-breadcrumb-item>任务OKR</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="taskTable-board">
            <!-- <div class="clearfix" style="background:#fff;margin-bottom:10px">
                <div class="buttons">
                    <a-form layout='inline'>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList" style="padding-left: 5px;">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>时间筛选</span>
                            </template>
                            <a-range-picker
                                :ranges="rangesData"
                                :value='rangesValue'
                                :disabled-date="disabledDate" 
                                allowClear
                                style="width: 230px"
                                @change="handleTime" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-input v-model='searchParams.search.log' style="width: 160px" allowClear placeholder="请输入关键词"></a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" html-type="submit" type="primary">搜索</a-button>
                        </a-form-item>
                        <a-form-item>
                            <a-popover  placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search">
                                <a-form layout='inline'>
                                    <a-form-item class="block-line" label="所属校区">
                                        <a-select v-model='searchParams.search.studio_id' mode="multiple" :maxTagCount="1" :maxTagTextLength='5'  allowClear showSearch placeholder="请选择校区" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item v-if="searchParams.search.is_sub == 1" class="block-line" label="课程顾问">
                                        <a-select mode="multiple" v-model='searchParams.search.course_consultant_id' allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption">
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进类型">
                                        <a-select v-model='searchParams.search.log_type' allowClear placeholder="请选择跟进类型">
                                        <a-select-option value="leads">线索跟进</a-select-option>
                                        <a-select-option value="customer">客户跟进</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item class="block-line" label="跟进状态">
                                        <a-select mode="multiple" v-model='searchParams.search.task_step' allowClear placeholder="请选择跟进状态">
                                        <a-select-option v-for="(d, index) of dealStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                                <a-badge>
                                    <a-button>更多<a-icon type="down" /></a-button>
                                </a-badge>
                            </a-popover>
                        </a-form-item>
                    </a-form>
                </div>
            </div> -->
            <div class="taskboards">
                <ul class="taskboards-ul">
                    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                    <li v-for="(item,index) in list" :key="item.top_id" class="taskboards-item">
                        <div class="taskboards-item-nav">
                            <span style="font-size: 15px;display: inline-block;padding-bottom: 5px;color: #333;">{{item.board_name}}</span>
                            <div>{{item.done_count}}/{{item.count}}</div>
                        </div>
                        <div>
                            <a-progress size="small" :percent="item.done_count/item.count*100" strokeColor="#00cca2" :show-info="false" />
                        </div>
                        <div
                            class="taskboards-item-box"
                            :class="item.show?'taskboards-item-calcF':'taskboards-item-calc'"
                        >
                            <draggable v-model="item.list" @end="e=>hadleDragMove(e,index)" group="site" :class="item.empty?'taskboards-card-height':''">
                                <transition-group :class="item.empty?'taskboards-card-height':''">
                                    <div v-for="(it,j) in item.list" :key="index + j" @click.stop="showFollowModal(it)" :class="item.empty?'taskboards-card-height':''" style="width: 100%;margin-bottom:10px" @mouseover="hadleOver(index,j)" @mouseout="handleOut">
                                        <a-card v-show="it.task_id" hoverable class="taskboards-card" :class="it.checked?'taskboards-card-bg':''" style="width: 100%">
                                            <div class="taskboards-card-nav taskboards-mb">
                                                <div style="margin-right:10px;width: 16px;"><a-checkbox :checked="it.checked" @click.stop="toChecked(it,index)"></a-checkbox></div>
                                                <div style="flex:1">
                                                    <div style="word-break: break-all;" :class="it.checked?'taskTable-line':''">
                                                        {{it.task_name}}
                                                    </div>
                                                    <div>
                                                        <span class="taskboards-card-icon" v-if="it.sub_count">
                                                            <a-icon type="apartment" />
                                                            <span> {{it.sub_done_count}} / {{it.sub_count}}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div v-if="it.main_employee" class="taskboards-card-info">
                                                    <a-avatar :src="it.main_employee.employee_avatar" :size="30" icon="user" />
                                                    <div class="taskboards-card-name">
                                                        {{it.main_employee.employee_name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </a-card>
                                        <div @click.stop v-show="!it.task_id" class="taskboards-card-has"></div>
                                    </div>
                                </transition-group>
                            </draggable>
                        </div>
                        <div @click.stop class="taskboards-add">
                            <div @click="showEditModal(index)" v-if="!item.show">
                                <a-icon style="color:#00cca2" type="plus" />
                                <span>新建任务</span>
                            </div>
                            <div v-if="item.show">
                                <a-textarea
                                v-model="item.task_name"
                                @pressEnter="handleOk(item,index)"
                                placeholder="请输入任务名称Objectives"
                                :auto-size="{ minRows: 2, maxRows: 3 }"
                                />
                                <div style="text-align:right;margin:5px 0">
                                    <span >
                                        <a-date-picker v-model="item.task_time" placeholder="请选择">
                                            <span style="cursor: pointer;" v-if="item.task_time">{{moment(item.task_time).format("MM-DD")}}</span>
                                            <a-icon style="cursor: pointer;color:#00cca2" v-else type="clock-circle" />
                                        </a-date-picker>
                                    </span>
                                    <span style="margin-left:5px">
                                        <a-popover v-model="item.isCheckWarp" title="选择" trigger="click">
                                            <div slot="content">
                                                <checkWarp />
                                            </div>
                                            <span>
                                                <span v-if="item.task_employee && item.task_employee.filter_name">{{item.task_employee.filter_name}}</span>
                                                <a-icon v-else @click="showCheckWarp(item,index)" type="user" />
                                            </span>
                                        </a-popover>
                                    </span>
                                </div>
                                <div style="text-align:right">
                                    <a-button @click="handleOk(item,index)" size="small" type="primary">确认</a-button>
                                    <a-button @click="hideEditModal(index)" style="margin-left:5px" size="small">取消</a-button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <editModal v-if="isEditModal" />
        <followModal v-if="followVisible" :item="modalData" :taskId="modalData.task_id" />
    </div>
</template>

<script>
    import C_ITEMS from '@/utils/items'
    import moment from 'moment'
    import draggable from 'vuedraggable'
    import editModal from './editModal'
    import followModal from './followModal'
    import ranges from "@/common/mixins/ranges"
    import infiniteScroll from 'vue-infinite-scroll'
    import authority from '@/common/mixins/authority'
    import checkWarp from '../approve/CheckWarp'
    export default {
        name:'followLog',
        data() {
            return {
                visible:false,
                loading:false,
                isEditModal:false,
                followVisible:false,
                authType:['crm','leads'],
                modalData:{},
                leadsId:'',
                isTab:'0',
                list: [],
                hoverIndex:[-1,-1],
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {
                        is_sub:0,
                        start_date:'',
                        end_date:'',
                    },
                    "sort": ''
                },
                studios:[],
                consultants:[],
                dealStatus:C_ITEMS.dealStatus,
                addIndex:-1,
            }
        },
        directives: { infiniteScroll },
        mixins: [ authority, ranges],
        components: {
            checkWarp,
            editModal,
            followModal,
            draggable
        },
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.getList()
        },
        methods: {
            moment,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(item) {
                this.loading = true
                let res = await this.$store.dispatch('oaTaskIndexAction', {})
                res.data.forEach(item=>{
                    if(item.list.length == 0){
                        item.list = [{}]
                        item.empty = true
                    }
                })
                this.list = res.data
                this.loading = false
            },
            hadleOver(index,j){
                this.hoverIndex = [index,j]
            },
            handleOut(){
                this.hoverIndex = [-1,-1]
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
            },
            handleTime(val,time,name){
                this.rangesValue = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
            closeSearch(){},
            reset(){},
            async searchList(e){
                e?e.preventDefault():''
                this.list.forEach(item=>{
                    item.page = 1
                    item.arr = []
                    item.count = 0
                    item.busy = true
                })
                let arr = []
                this.getList()
                this.list = arr
                this.$forceUpdate()

            },
            showFollowModal(item) {
                this.modalData = item
                this.followVisible = true
            },
            hideFollowModal(type) {
                if(type){
                    this.getList()
                }
                this.followVisible = false
            },
            showEditModal(index) {
                this.addIndex = index
                this.list[index].show = true
            },
            hideEditModal(index) {
                this.list[index].show = false
                this.list[index].task_name = ''
                this.list[index].task_time = ''
                this.list[index].task_employee = ''
            },
            showCheckWarp(item,index){

            },
            hideCheckWarp(val){
                if(val){
                    this.list[this.addIndex].task_employee = JSON.parse(this.$ls.get('check_name_list'))[0]
                }
                this.list[this.addIndex].isCheckWarp = false
            },
            async handleOk(item,index){
                if(!item.task_name){
                    this.$message.warning('请输入任务名称Objectives！！')
                    return false
                }
                let obj = {
                    task_name: item.task_name,
                    top_id:item.top_id
                }
                if(item.task_time){
                    obj.stop_time = moment(item.task_time).format('YYYY-MM-DD')
                }
                if(item.task_employee){
                    obj.main_employee_id = item.task_employee.employee_id
                }
                await this.$store.dispatch('oaTaskAddAction',obj)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.list[this.addIndex].show = false
                    this.getList()
                })
            },
            async toChecked(item,index){
                await this.$store.dispatch('oaTaskCheckAction',{task_id:item.task_id,checked:item.checked?0:1})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    item.checked = !item.checked
                    item.checked?this.list[index].done_count++ : this.list[index].done_count--
                })
            },
            async hadleDragMove(e,index){
                let arr = this.list[index].list.map(item=>item.task_id)
                let obj = []
                this.list.forEach((item,index)=>{
                    obj.push({top_list:[],top_id:item.top_id})
                    item.list.forEach(it=>{
                        if(it.task_id){
                            obj[index].top_list.push(it.task_id)
                        }
                    })
                })
                await this.$store.dispatch('oaTaskReOrderAction',{board_list:obj})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.getList()
                })
            },
        },
    }
</script>

<style lang="scss">
    .taskTable-board{
        height: calc(100% - 51px);
        padding: 15px;
    }
    .taskTable-line{
        text-decoration: line-through;
    }
    .taskboards{
        width: 100%;
        height: calc(100% - 40px);
        &-ul{
            list-style: none;
            width: 100%;
            overflow: auto;
            margin-bottom:initial;
            padding-inline-start:0px;
            display: flex;
            height: 100%;
            .ant-list-item{
                border-bottom:none !important;
                padding: 3px 0 !important;
            }
        }
        &-mb{
            margin-bottom: 5px;
        }
        &-item{
            max-width: 295px;
            height: 100%;
            background: #fff;
            border-radius: 2px;
            margin-right: 10px;
            flex-grow:0;
            flex-shrink: 0;
            flex-basis: 295px;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,.05);
            padding: 5px;
            &-nav{
                display: flex;
                align-items: center;
                justify-content: space-between;
                h1{
                    margin-bottom: 0;
                }
            }
            &-box{
                overflow-y: auto;
            }
            &-calc{
                height: calc(100% - 70px)
            }
            &-calcF{
                height: calc(100% - 160px)
            }
        }
        &-card{
            position: relative;
            .ant-card-body{
                padding: 10px;
            }
            &-nav{
                display: flex;
                // align-items: center;
                // justify-content: space-between;
            }
            &-info{
                // display: flex;
                // align-items: center;
                width: 60px;
                text-align: center;
            }
            &-name{
                width: 60px;
                text-align: center;
                overflow: hidden;
                font-size: 12px;
                white-space: nowrap;
                word-break: break-all;
                text-overflow: ellipsis;
            }
            &-icon{
                margin-right: 8px;
            }
            &-has{
                height: calc(100% - 40px);
            }
            &-height{
                height: 100%;
            }

        }
        &-card-bg{
            background: #f3f3f3;
        }
        &-ellipsis{
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
        &-add{
            color: #00cca2;
            cursor: pointer;
            span{
                margin-left: 5px;
            }
        }
    }
</style>