<template>
    <a-drawer placement="right" title="" :footer="null" :visible="visible" :mask="false" wrapClassName="taskDrawer"
     cancelText="取消" okText="保存" width="50%" @close="handleCancel" :zIndex='4000'>
        <div @click="handleBlur(showInputIndex)" class='taskFollow'>
            <div>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <div class="taskFollow-nav taskFollow-mar">
                    <a-row>
                        <a-col :span="8">
                        </a-col>
                        <a-col style="text-align:right;padding-right:20px" :span="16">
                            <a-dropdown :overlayStyle='{zIndex:4200}'>
                                <a-menu slot="overlay">
                                    <!-- <a-menu-item key="1">
                                        归档
                                    </a-menu-item> -->
                                    <a-menu-item @click="toDel" key="2">
                                        删除
                                    </a-menu-item>
                                </a-menu>
                                <a-button>更多<a-icon type="down" /> </a-button>
                            </a-dropdown>
                        </a-col>
                    </a-row>
                </div>

                <div class="taskFollow-mar">
                    <a-row :gutter="16">
                        <a-col :span="18" class="taskFollow-task-name">
                            <a-checkbox :checked="detailInfo.checked" v-if="!showTaskName" @click="toChecked(detailInfo.task_id,detailInfo.checked,detailInfo)"></a-checkbox>
                            <span @click="showTaskName=true;update_task_name = detailInfo.task_name" v-if="!showTaskName" :class="detailInfo.checked?'taskDrawer-line':''" style="margin:0 5px;font-size:20px;cursor: pointer;">{{detailInfo.task_name}}</span>
                            <span v-if="showTaskName">
                                <a-textarea
                                    v-model="update_task_name"
                                    placeholder="请输入"
                                    :auto-size="{ minRows: 3, maxRows: 3 }"
                                    @blur="to_update('task_name',update_task_name,1)"
                                />
                            </span>
                        </a-col>
                        <a-col :span="6">
                            <div>
                                <div>
                                    <span>负责人：</span>
                                    <span style="margin-left:5px">
                                        <a-popover overlayClassName="taskFollow-popover" v-model="isResponsible" title="选择" trigger="click">
                                            <div slot="content">
                                                <checkWarp :checkWarpNum="checkWarpNum" />
                                            </div>
                                            <span>
                                                <span class="show-close" @click="showCheckWarp(-3,main_employee)" v-if="main_employee && main_employee.filter_name">
                                                    {{main_employee.filter_name}}
                                                    <a-icon @click.stop="handleClear(1)" class="show-close-icon" type="close-circle" />
                                                </span>
                                                <a-icon @click="showCheckWarp(-3)" v-else type="plus-circle" />
                                            </span>
                                        </a-popover>
                                    </span>
                                </div>
                                <div>
                                    <span>截止日期：</span>
                                    <a-date-picker v-model="stop_time" placeholder="请选择" :popupStyle='{zIndex:4200}' @change="to_update('stop_time',moment(stop_time).format('YYYY-MM-DD'),3)">
                                        <span class="show-close" style="cursor: pointer;" v-if="stop_time">
                                            {{moment(stop_time).format("MM-DD")}}
                                            <a-icon @click.stop="handleClear(0)" class="show-close-icon" type="close-circle" />
                                        </span>
                                        <a-icon style="cursor: pointer;" v-else type="clock-circle" />
                                    </a-date-picker>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </div>
                
                <div class="taskFollow-mar">
                    <a-textarea
                        v-if="showTextarea"
                        v-model="description"
                        placeholder="请输入"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                    />
                    <div v-if="!showTextarea">
                        <span style="cursor: pointer;" @click="showTextarea=true;description=detailInfo.description" v-if="detailInfo.description">{{detailInfo.description}}</span>
                        <span style="cursor: pointer;" @click="showTextarea=true;description=detailInfo.description" v-else>暂无描述 <a-icon type="plus" />添加描述</span>
                    </div>
                    <div style="margin-top:5px" v-if="showTextarea">
                        <a-button @click="to_update('description',description,4)" type="primary">确认</a-button>
                        <a-button style="margin-left:5px" @click="showTextarea=false;description=''">取消</a-button>
                    </div>
                </div>

                <div class="taskFollow-mar">
                    <a-row align="top">
                        <a-col :span="2">
                            <span><a-icon type="user" />参与人:</span>
                        </a-col>
                        <a-col :span="20">
                            <a-popover overlayClassName="taskFollow-popover" v-model="isCheckWarp" title="选择" trigger="click">
                                <div slot="content" class="taskFollow-pop">
                                    <checkWarpmMltiple />
                                </div>
                                <div style="display:flex;align-items:center;flex-wrap:wrap;">
                                    <a-tag style="margin: 2px 4px;border-radius:10px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.employee_name}}</a-tag>
                                    <a-icon @click="showCheckWarp(-2,check_name_list)" style='fontSize:18px' type="plus-circle" />
                                </div>
                            </a-popover>
                        </a-col>
                    </a-row>
                </div>

                <div class="taskFollow-mar">
                    <div style="margin-bottom:10px"><a-icon type="block" /> 关联业务</div>
                    <div class="taskFollow-customer" @click.stop="showFollowModal(item)" v-for="item in selectedRecord" :key="item.task_id"><a-icon type="user" /> {{item.customer_name}}</div>
                    <div class="taskFollow-customer" @click="isCustomer=true"><a-icon type="link" /> 关联业务</div>
                </div>

                <div class="taskFollow-mar">
                    <div style="display:flex;align-items:center;margin-bottom:10px">
                        <a-icon type="block" />
                         <span>子任务KR（{{detailInfo.sub_done_count}} / {{detailInfo.sub_count}}）</span>
                         <a-progress style="flex:1" size="small" :percent="detailInfo.sub_done_count/detailInfo.sub_count * 100" strokeColor="#00cca2" :show-info="false" />
                    </div>
                    <div>
                        <draggable v-model="sub_task_list" @end="hadleDragMove">
                            <transition-group>
                                <div class="taskFollow-customer" v-for="(item,index) in sub_task_list" :key="'a' + index">
                                    <div>
                                        <div @click.stop v-if="!item.showInput" class="taskFollow-customer-item">
                                            <a-checkbox :checked="item.checked" @click="toChecked(item.task_id,item.checked,item,1)"></a-checkbox>
                                            <span style="margin-left:10px">{{index+1}}.</span><span :class="item.checked?'taskDrawer-line':''" style="margin-left:10px">{{item.task_name}}</span>
                                            <a-icon v-if="!item.checked" @click="item.showInput=true;showInputIndex=index" class="taskFollow-customer-item-icon" style="margin-left:5px" type="edit" />
                                            <a-icon @click="toDelChild(item,index)" class="taskFollow-customer-item-icon" style="margin-left:5px" type="delete" />
                                            <div v-if="!item.checked" class="taskFollow-customer-itemR">
                                                <span>{{item.stop_time?moment(item.stop_time).format('YYYY-MM-DD'):''}}</span>
                                                <span style="margin-left:10px">{{item.main_employee.filter_name}}</span>
                                            </div>
                                            <div v-if="item.checked" class="taskFollow-customer-itemR">
                                                <span>{{item.checked_at}}</span>
                                                <span style="margin-left:10px">{{item.checked_employee.employee_name}}</span>
                                            </div>
                                        </div>
                                        <div @click.stop v-if="item.showInput">
                                            <a-input placeholder="请输入" v-model="item.task_name" :maxLength="40" @pressEnter="handleBlur(showInputIndex)">
                                                <div slot="suffix">
                                                    <span >
                                                        <a-date-picker v-model="item.stop_time" placeholder="请选择" :popupStyle='{zIndex:4200}'>
                                                            <span style="cursor: pointer;" v-if="item.stop_time">{{moment(item.stop_time).format("MM-DD")}}</span>
                                                            <a-icon style="cursor: pointer;color:#00cca2" v-else type="clock-circle" />
                                                        </a-date-picker>
                                                    </span>
                                                    <span style="margin-left:5px">
                                                        <a-popover overlayClassName="taskFollow-popover" v-model="item.isCheckWarp" title="选择" trigger="click">
                                                            <div slot="content">
                                                                <checkWarp />
                                                            </div>
                                                            <span>
                                                                <span @click="showCheckWarp(index,item)" v-if="item.main_employee && item.main_employee.filter_name">{{item.main_employee.filter_name}}</span>
                                                                <a-icon v-else @click="showCheckWarp(index,item)" type="user" />
                                                            </span>
                                                        </a-popover>
                                                    </span>
                                                </div>
                                            </a-input>
                                        </div>
                                    </div>
                                </div>
                            </transition-group>
                        </draggable>
                        <div class="taskFollow-customer">
                            <div @click.stop>
                                <div v-if="!showInput" @click="showInput=true;showInputIndex=-1">
                                    <a-icon style="color:#00cca2" type="plus" />
                                    <span>添加子任务KR</span>
                                </div>
                                <div v-if="showInput">
                                    <a-input placeholder="请输入子任务KR" v-model="task_name" :maxLength="40" @pressEnter="handlePressEnter(showInputIndex)">
                                        <div slot="suffix">
                                            <span >
                                                <a-date-picker v-model="task_time" placeholder="请选择" :popupStyle='{zIndex:4200}'>
                                                    <span style="cursor: pointer;" v-if="task_time">{{moment(task_time).format("MM-DD")}}</span>
                                                    <a-icon style="cursor: pointer;color:#00cca2" v-else type="clock-circle" />
                                                </a-date-picker>
                                            </span>
                                            <span style="margin-left:5px">
                                                <a-popover overlayClassName="taskFollow-popover" v-model="isChildCheckWarp" title="选择" trigger="click">
                                                    <div slot="content">
                                                        <checkWarp />
                                                    </div>
                                                    <span>
                                                        <span @click="showCheckWarp(-1,1)" v-if="task_employee && task_employee.filter_name">{{task_employee.filter_name}}</span>
                                                        <a-icon @click="showCheckWarp(-1)" v-else type="user" />
                                                    </span>
                                                </a-popover>
                                            </span>
                                        </div>
                                    </a-input>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="taskFollow-mar">
                    <div style="margin-bottom:10px"><a-icon type="block" /> 操作记录</div>
                    <taskLog :leadsId="item.task_id" :refreshNum="refreshNum" />
                </div>

                <div class="taskFollow-mar">
                    <div style="margin-bottom:10px"><a-icon type="block" /> 发表评论</div>
                    <div class="taskFollow-send">
                        <div>
                            <a-mentions v-model="mentionsValue" :loading="stuLoading" @search="onSearch" @select="onSelect" rows="4" placeholder="请使用@xxx来选择员工">
                                <a-mentions-option style="z-index:4300" v-for="item in staffList" :key="item.employee_id" :value="`${item.filter_name}+=${item.employee_id}+=`">
                                    <a-avatar :src="item.employee_avatar" icon="user" />
                                    {{item.filter_name}}
                                </a-mentions-option>
                            </a-mentions>
                        </div>
                        <div class="taskFollow-send-icon">
                            <div class="taskFollow-send-iconL">
                                <a-upload
                                    name="file" 
                                    multiple
                                    class="taskFollow-send-upload"
                                    :data="uploadParams"
                                    :action="uploadUrl" 
                                    :beforeUpload="beforeImageUpload"
                                    :showUploadList="false"
                                    listType="picture"
                                    @change="changeFile">
                                    <a-button>
                                        <a-icon type="picture" /> 图片
                                    </a-button>
                                </a-upload> 
                            </div>
                            <div>
                                <a-button :loading="sendLoading" @click="toSend" type="primary">发布</a-button>
                            </div>
                        </div>
                        <div class="taskFollow-send-img">
                            <div class="taskFollow-send-imgBox" v-for="(item,index) in fileList" :key="index">
                                <img :preview="index" :src="`http://i01.wedots.cn/${item.response.key}`">
                                <div @click.stop="delUpload(index,'fileList')" class="taskFollow-send-close">
                                    <a-icon type="close" />
                                </div>
                            </div>
                            <div v-if="upLoadLoading" class="taskFollow-send-loading">
                                <vue-element-loading :active="upLoadLoading" color="#00cca2" spinner="spinner"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <customer v-if="isCustomer" />
        <crmFollowModal :authType="authType" :item="modalData" :leadsId='leadsId' isTab='1' v-if="followVisible"/>
    </a-drawer>
</template>

<script>
    import moment from 'moment'
    import checkWarp from '../approve/CheckWarp'
    import checkWarpmMltiple from './CheckWarp'
    import taskLog from './taskLog'
    import url from '@/utils/URL'
    import draggable from 'vuedraggable'
    import { delay } from '@/utils/timeFormat'
    import createFilePath from '@/utils/tools'
    import infiniteScroll from 'vue-infinite-scroll'
    import customer from '@/views/market/huidongApply/coachDaily/customer'
    import crmFollowModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'followModal',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        directives: { infiniteScroll },
        data() {
            return {
                loading:false,
                visible: false,
                stuLoading: false,
                sendLoading: false,
                upLoadLoading: false,
                description:'',
                update_task_name:'',
                showTextarea:false,
                showTaskName:false,
                isCheckWarp:false,
                check_name_list:[],
                selectedRecord:[],
                selectedRowKeys:[],
                isCustomer:false,
                sub_task_list:[],
                showInput:false,
                task_name:'',
                task_time:null,
                task_employee:'',
                main_employee:'',
                stop_time:null,
                isResponsible: false,
                followVisible: false,
                isChildCheckWarp: false,
                checkWarpNum:0,
                checkWarpIndex:'',
                showInputIndex:-1,
                detailInfo:{},
                main_employee:{},
                checked_employee:{},
                leadsId:'',
                modalData:{},
                authType:['crm','customer'],
                mentionsValue:'',
                staffList:[],
                uploadUrl: url.uploadQiNiu, 
                uploadParams: {},
                fileList: [],
                employee_id:'',
                filter_name:'',
                employee_id_list:[],
                filter_name_list:[],
                refreshNum:0

            }
        },
        props:{
            item: Object,
            taskId: Number
        },
        components: {
            taskLog,
            customer,
            checkWarp,
            crmFollowModal,
            checkWarpmMltiple,
            draggable,
        },
        created () {
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
            this.$ls.remove('localSelectedRecord')
            this.$ls.remove('localSelectedRowKeys')
            this.visible = true
            this.loading = true
            this.getDetail()
        },
        methods: {
            moment,
            async getDetail(){
                await this.$store.dispatch('oaTaskDeatilAction',{task_id:this.item.task_id})
                .then(res=>{
                    this.detailInfo = res.data
                    this.stop_time = res.data.stop_time?moment(res.data.stop_time) : null
                    this.description = res.data.description
                    this.sub_task_list = res.data.sub_task_list
                    this.main_employee = res.data.main_employee
                    this.check_name_list = res.data.owner_employee_list
                    this.selectedRecord = res.data.customer_ids
                    this.checked_employee = res.data.checked_employee
                    this.selectedRowKeys = res.data.customer_ids.map(item=>item.customer_id)
                    this.$ls.set('localSelectedRecord',JSON.stringify(this.selectedRecord))
                    this.$ls.set('localSelectedRowKeys',JSON.stringify(this.selectedRowKeys))
                    this.loading = false
                })
            },
            async to_update(name,value,num){
                await this.$store.dispatch('oaTaskUpdateAction',{task_id:this.item.task_id,[name]:value})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    if(num == 1){
                        this.detailInfo.task_name = this.update_task_name
                        this.update_task_name = ''
                        this.showTaskName = false
                    }else if(num == 2){
                        this.main_employee = JSON.parse(this.$ls.get('check_name_list'))[0]
                        this.isResponsible = false
                    }else if(num == 3){
                        this.detailInfo.stop_time = this.stop_time
                    }else if(num == 4){
                        this.detailInfo.description = this.description
                        this.showTextarea = false
                    }else if(num == 5){
                        this.selectedRecord = JSON.parse(this.$ls.get('localSelectedRecord'))
                        this.selectedRowKeys = JSON.parse(this.$ls.get('localSelectedRowKeys'))
                        this.isCustomer = false
                    }else if(num == 6){
                        this.main_employee = ''
                        this.$ls.remove('check_name_list')
                    }else if(num == 7){
                        this.stop_time = null
                        this.detailInfo.stop_time = null
                    }else if(num == 8){
                        this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                        this.isCheckWarp = false
                    }
                })
            },
            async to_update_chaild(num){
                let child = this.sub_task_list[num]
                let obj = {
                    task_id:child.task_id,
                    task_name:child.task_name,
                    main_employee_id:child.main_employee.employee_id,
                    stop_time:child.stop_time?moment(child.stop_time).format('YYYY-MM-DD'):null
                }
                await this.$store.dispatch('oaTaskUpdateAction',obj)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.getDetail()
                })
                .catch(err=>{
                    this.getDetail()
                })
            },
            async getStaff(obj){
                this.stuLoading = true
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
                this.stuLoading = false
            },
            handleCancel() {
                this.parent.hideFollowModal(0)
            },
            hideCheckWarp(val){
                if(val){
                    if(this.checkWarpIndex == -1){
                        this.task_employee = JSON.parse(this.$ls.get('check_name_list'))[0]
                        this.isChildCheckWarp = false
                    }else if(this.checkWarpIndex == -2){
                        let check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                        let arr = check_name_list.map(item=>item.employee_id)
                        this.to_update('owner_employee_id',arr,8)
                    }else if(this.checkWarpIndex == -3){
                        this.to_update('main_employee_id',JSON.parse(this.$ls.get('check_name_list'))[0].employee_id,2)
                    }else{
                        this.sub_task_list[this.checkWarpIndex].main_employee = JSON.parse(this.$ls.get('check_name_list'))[0]
                        this.sub_task_list[this.checkWarpIndex].isCheckWarp = false
                    }
                }else{
                    if(this.checkWarpIndex == -1){
                        this.isChildCheckWarp = false
                    }else if(this.checkWarpIndex == -2){
                        this.isCheckWarp = false
                    }else if(this.checkWarpIndex == -3){
                        this.isResponsible = false
                    }else{
                        this.sub_task_list[this.checkWarpIndex].isCheckWarp = false
                    }
                }
            },
            hideCustomer(val) {
                if(val){
                    this.to_update('customer_ids',JSON.parse(this.$ls.get('localSelectedRowKeys')),5)
                }else{
                    this.isCustomer = false
                }
            },
            showCheckWarp(index,item){
                this.checkWarpNum++
                this.checkWarpIndex = index
                this.$ls.remove('check_name_list')
                this.$ls.remove('send_employee_ids')
                this.$ls.remove('send_employee_list')
                if(index === -1){

                }else if(index === -2){
                    if(item){
                        if(this.check_name_list.length>0){
                            let arr = this.check_name_list.map(item=>item.employee_id)
                            this.$ls.set('check_name_list',JSON.stringify(this.check_name_list))
                            this.$ls.set('send_employee_ids',JSON.stringify(arr))
                            this.$ls.set('send_employee_list',JSON.stringify(this.check_name_list))
                        }
                    }
                }else if(index === -3){
                    if(item){
                        this.$ls.set('check_name_list',JSON.stringify([item]))
                        this.$ls.set('send_employee_ids',JSON.stringify([item.employee_id]))
                        this.$ls.set('send_employee_list',JSON.stringify([item]))
                    }
                }else{
                    this.$ls.set('check_name_list',JSON.stringify([item.main_employee]))
                    this.$ls.set('send_employee_ids',JSON.stringify([item.main_employee.employee_id]))
                    this.$ls.set('send_employee_list',JSON.stringify([item.main_employee]))
                }
            },
            async handleBlur(num){
                if(num === -1){
                    if(this.task_name){
                        let obj = {
                            pid: this.detailInfo.task_id,
                            task_name: this.task_name, 
                            stop_time:this.task_time?moment(this.task_time).format('YYYY-MM-DD'):'',
                            main_employee_id:this.task_employee.employee_id
                        }
                        await this.$store.dispatch('oaTaskAddAction',obj)
                        .then(res=>{
                            this.$message.success('操作成功！！')
                            this.task_name = ''
                            this.task_time = ''
                            this.task_employee = ''
                            this.getDetail()
                        })
                    }
                    this.showInput = false
                }else{
                    this.to_update_chaild(num)
                }
            },
            async handlePressEnter(num){
                if(num === -1){
                    if(this.task_name){
                        let obj = {
                            pid: this.detailInfo.task_id,
                            task_name: this.task_name, 
                            stop_time:this.task_time?moment(this.task_time).format('YYYY-MM-DD'):'',
                            main_employee_id:this.task_employee.employee_id
                        }
                        await this.$store.dispatch('oaTaskAddAction',obj)
                        .then(res=>{
                            this.$message.success('操作成功！！')
                            this.task_name = ''
                            this.task_time = ''
                            this.task_employee = ''
                            this.getDetail()
                        })
                    }
                    // this.showInput = false
                }
            },
            async toDel(item,index){
                let that = this
                this.$confirm({
                    title: `您确定删除该任务`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    zIndex: 4200,
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('oaTaskDeleteAction', {task_id:that.detailInfo.task_id})
                        .then(res=>{
                            that.$message.success('操作成功！！')
                            that.parent.hideFollowModal(1)
                            resolve(res)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            async toDelChild(item,index){
                let that = this
                this.$confirm({
                    title: `您确定删除该任务`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    zIndex: 4200,
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('oaTaskDeleteAction', {task_id:item.task_id})
                        .then(res=>{
                            that.$message.success('操作成功！！')
                            that.sub_task_list.splice(index,1)
                            resolve(res)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            },
            async toChecked(task_id,check,item,num){
                let that = this
                await this.$store.dispatch('oaTaskCheckAction',{task_id,checked:check?0:1})
                .then(res=>{
                    if(num){
                        if(check){
                            that.detailInfo.sub_done_count-=1
                        }else{
                            that.detailInfo.sub_done_count+=1
                        }
                    }
                    this.$message.success('操作成功！！')
                    item.checked = !item.checked
                })
            },
            handleClear(num){
                if(num == 1){
                    this.to_update('main_employee_id','',6)
                }else{
                     this.to_update('stop_time','',7)
                }
            },
            showFollowModal(item) {
                this.modalData = item
                this.leadsId = item.customer_id
                this.followVisible = true
            },
            hideFollowModal() {
                this.followVisible = false
            },
            onSearch(search){
                this.stuLoading = !!search
                this.staffList = []
                let obj = {
                    q: search,
                }
                delay(() => {
                    this.getStaff(obj)
                }, 500)
            },
            onSelect(option) {
                let strArr = this.mentionsValue.split('+=')
                this.mentionsValue= strArr[0] + strArr[2]
                this.employee_id = strArr[1]
                this.filter_name = strArr[0]
                this.employee_id_list.push(strArr[1])
                this.filter_name_list.push(strArr[0])
            },
            async beforeImageUpload(file) {
                if (!file) { return false }
                let res = await this.$store.dispatch('imageTokenAction', {})
                if (res) {
                    this.uploadParams.token = res.data.uptoken
                    this.uploadParams.key = createFilePath(file.name)
                    return true
                }
                return false
            },
            changeFile({ file, fileList }) {
                this.upLoadLoading = true
                if(file.status == "done"){
                    this.fileList.push(file)
                    this.upLoadLoading = false
                }
                console.log(file,fileList)
            },
            delUpload(index,name){
                this[name].splice(index,1)
            },
            async toSend(){
                if(!this.mentionsValue){
                    this.$message.warning('请输入评论内容!!')
                    return false
                }
                let obj = {
                    task_id: this.item.task_id,
                    content: this.mentionsValue
                }
                if (this.fileList.length) {
                    let arr = []
                    for (let file of this.fileList) {
                        if (file.response.key) {
                            arr.push(file.response.key)
                        }
                    }
                    obj.content_images = arr
                }
                if(this.employee_id_list.length>0){
                    let arr = []
                    this.filter_name_list.forEach((item,index)=>{
                        if(this.mentionsValue.indexOf(item) !=-1){
                            arr.push(this.employee_id_list[index])
                        }
                    })
                    obj.employee_id = Array.from(new Set(arr))
                }
                this.sendLoading = true
                await this.$store.dispatch('oaTaskAddLogAction',obj)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.mentionsValue = ''
                    this.employee_id = ''
                    this.filter_name = ''
                    this.fileList = []
                    this.employee_id_list = []
                    this.filter_name_list = []
                    this.refreshNum++
                    this.sendLoading = false
                })
                .catch(err=>{
                    this.sendLoading = false
                })
            },
            async hadleDragMove(){
                let arr = this.sub_task_list.map(item=>item.task_id)
                await this.$store.dispatch('oaTaskReOrderAction',{top_list:arr})
                .then(res=>{
                    this.$message.success('操作成功！！')
                })
            },
        },
        watch: {
            taskId(newValue, oldValue) {
                this.loading = true
                this.getDetail()
            }
        },
    }
</script>

<style lang="scss">
    .taskDrawer{
        width: 50% !important;
        .ant-drawer-open{
            width: 50% !important;
        }
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
        .ant-drawer-mask{
            height: 0 !important;
        }
        .ant-drawer-body{
            height: 100%;
        }
    }
    .taskFollow-popover{
        z-index: 4200;
    }
    .taskFollow{
        height: 100%;
        &-customer{
            padding: 8px;
            background-color: #f5f7fa;
            border-radius: 2px;
            position: relative;
            margin-bottom: 5px;
            // color: #3e84e9;
            cursor: pointer;
            &-item{
                display: flex;
                align-items: center;
                position: relative;
            }
            &-itemR{
                position: absolute;
                right: 5px;
            }
        }
        &-mar{
            margin-bottom: 20px;
        }
        &-log{
            max-height: 300px;
            overflow:scroll;
        }
        &-send{
            width: 100%;
            margin: 0 auto;
            background: #fff;
            padding: 10px;
            border-radius: 2px;
            border: 1px solid #e8e8e8;
            textarea{
                resize: none;
            }
            &-icon{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
            }
            &-iconL{
                display: flex;
                align-items: center;
                .ant-btn{
                    border: none;
                    box-shadow:none;
                    padding: 0px;
                }
            }
            &-upload{
                margin-right: 15px;
            }
            &-img{
                display: flex;
                flex-wrap: wrap;
                img{
                    height: 60px;
                }
            }
            &-imgBox{
                margin-right: 5px;
                margin-bottom: 5px;
                position: relative;
            }
            &-close{
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(250,250,250,.5);
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
            }
            &-loading{
                width: 60px;
                height: 60px;
            }

        }
        .show-close{
            position: relative;
            .show-close-icon{
                position: absolute;
                top: -10px;
                right: -15px;
                cursor: pointer;
                color: red;
                display: none;
            }
        }
        .show-close:hover{
            .show-close-icon{
                display: block;
            }
        }
        .taskFollow-customer-item{
            .taskFollow-customer-item-icon{
                display: none;
            }
        }
        .taskFollow-customer-item:hover{
            .taskFollow-customer-item-icon{
                display: inline-block;
            }
        }
        .taskFollow-task-name{
            .ant-checkbox-inner{
                width: 24px;
                height: 24px;
                &::after{
                    top: 9px;
                    left: 5px;
                    width: 9px;
                    height: 15px;
                }
            }
        }
    }
    .taskDrawer-line{
        text-decoration: line-through;
    }
    .ant-mentions-dropdown{
        z-index: 4300!important;
    }
</style>